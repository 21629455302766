import { useEffect, useState, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'

import { MisoColors } from '../colors'
import { formatShortDateForAxis, formatNumber } from '../Utils/StringFormatHelpers'
import { formatTooltipDateWithMinutes } from '../Utils/ToolTipHelpers'
import { FirstTierData, FirstTierInstance, ImportInterval, NsiData, NsiInstanceData } from '../Interfaces/DataBroker'
import { ChartProps, XYPoint } from '../Interfaces/Charts'
import { X_TICK_INTERVAL, refId2Date, convertDateTimeToISO } from '../Utils/DateHelpers'
import { SCHEDULED_INTERCHANGE_SUFFIX, ACTUAL_INTERCHANGE_SUFFIX, FIRST_TIER_SCHEDULED_SUFFIX, DESCRIPTION_MESSAGE, DOWNLOAD_MESSAGE, POPOUT_MESSAGE } from '../Constants'
import VCenteredModal from '../Components/VCenteredModal'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { Button } from 'react-bootstrap'
import { Download } from '../Utils/Downloader'
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput'
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel'


// setup series arrays
let netScheduled: XYPoint[] = [];
let actual: XYPoint[] = [];
let AEC: XYPoint[] = [];
let AECI: XYPoint[] = [];
let CSWS: XYPoint[] = [];
let GLHB: XYPoint[] = [];
let LGEE: XYPoint[] = [];
let MHEB: XYPoint[] = [];
let MISO: XYPoint[] = [];
let OKGE: XYPoint[] = [];
let ONT: XYPoint[] = [];
let PJM: XYPoint[] = [];
let SOCO: XYPoint[] = [];
let SPA: XYPoint[] = [];
let SWPP: XYPoint[] = [];
let TVA: XYPoint[] = [];
let WAUE: XYPoint[] = [];

let firstTierBas: string[] = ["AECI", "LGEE", "MHEB", "ONT", "PJM", "SOCO", "SPA", "SWPP", "TVA"];

// setup dates and labels
let mktDay: Date;
let prevDayDt: Date;
let prevDayFriendly: string;
let mktDayFriendly: string;

let intervalString: string;

let initialOptions: Highcharts.Options = {
    credits: {
        enabled: false
    },
    title:{
        text: ''
    },
    lang: {
        noData: "No data was received"
    },
    plotOptions: {
        line: {
            marker: {
                enabled: false,
            },
        },
    },
    legend: {
        maxHeight: 1000,
        alignColumns: false,
        itemDistance: 10,
        width: '100%',
        layout: 'horizontal',
        // itemHiddenStyle: {"color": "rgb(102, 102, 102)", "cursor": "pointer", "font-size": "0.8em", "text-decoration": "none", "fill": "rgb(102, 102, 102)"},
        itemStyle: {
            display: "flex"
        },
        itemMarginBottom: 5,
        className:'w-100 d-flex justify-content-between',
        labelFormatter: function () {
            return `<div class="fs14 w-100 d-flex justify-content-between margin-btm-5"><div>${this.name}</div> <div class='fw-normal'> </div></div>`;
        },
    },
    xAxis: [
        // Main axis
        {
            title: {
                text: `Hours EST`,
                style: {
                    fontWeight: 'bold',
                },
            },
            type: 'datetime',
        },
    ],
    yAxis: {
        title: {
            text: "MWs",
            style: {
                fontWeight: "bold",
            },
        },
        plotLines: [
            {
                value: 0,
                width: 2,
                color: MisoColors.black
            }
        ],
        tickInterval: 1000,
    },
    tooltip: {
        shared: true,
        formatter: function() {
            if(this.points == null) return false;
            const x = this.x as number
            return this.points.reduce(formatTooltip, '<b>' + formatTooltipDateWithMinutes(new Date(x)) + '</b>'); // points.reduce(format_func(prev_val, val), initial_prev_val)
        }
    },
};

function formatTooltip(s: string, point: Highcharts.TooltipFormatterContextObject): string{
    
    const y = point.y as number;

    // keep the existing tooltip, add break line
    var tooltip = s + '<br />';
    
    // now add to it
    tooltip += '<span style="color:'+ point.color +'">\u25cf</span> '+ point.series.name +': <b>' + formatNumber(y) +' MW</b>';
    return tooltip;
}

function parseFirstTierData(ftData: FirstTierData, firstTime: number): void {
    ftData.instance.forEach((i: FirstTierInstance) => {
        const dt = new Date(i.timestamp);
        // need to convert to EST for the chart.
        // they come in as UTC. This is hardcoded to 5 because these are ALWAYS EST. (never edt)
        // dt.setHours(dt.getHours() - 5);
        const t = dt.getTime();
        if(t >= firstTime)
        {
            AEC.push({x: t, y: parseFloat(i.AEC)});
            AECI.push({x: t, y: parseFloat(i.AECI)});
            CSWS.push({x: t, y: parseFloat(i.CSWS)});
            GLHB.push({x: t, y: parseFloat(i.GLHB)});
            LGEE.push({x: t, y: parseFloat(i.LGEE)});
            MHEB.push({x: t, y: parseFloat(i.MHEB)});
            MISO.push({x: t, y: parseFloat(i.MISO)});
            OKGE.push({x: t, y: parseFloat(i.OKGE)});
            ONT.push({x: t, y: parseFloat(i.ONT)});
            PJM.push({x: t, y: parseFloat(i.PJM)});
            SOCO.push({x: t, y: parseFloat(i.SOCO)});
            SPA.push({x: t, y: parseFloat(i.SPA)});
            SWPP.push({x: t, y: parseFloat(i.SWPP)});
            TVA.push({x: t, y: parseFloat(i.TVA)});
            WAUE.push({x: t, y: parseFloat(i.WAUE)});
        }
    })
}

function parseData(data: [NsiData, ImportInterval[], FirstTierData]){
    const [nsi, imports, ftData] = data;
    
    mktDay = refId2Date(nsi.RefId);
    prevDayDt = new Date(mktDay);
    prevDayDt.setDate(prevDayDt.getDate() - 1);
    intervalString = data[0].RefId;
    
    // friend-ify dates
    prevDayFriendly = formatShortDateForAxis(prevDayDt);
    mktDayFriendly = formatShortDateForAxis(mktDay);

    let midnight = new Date(mktDay);
    midnight.setHours(0, 0, 0, 0);

    const firstTime = new Date(nsi.instance[0].timestamp).getTime();

    nsi.instance.forEach((inst: NsiInstanceData, index: number) => {
        const dt = new Date(inst.timestamp);
        netScheduled.push({x: dt.getTime(), y: parseFloat(inst.NSI)});
    });

    imports.forEach((inst: ImportInterval, index: number) => {
        const isoDate = convertDateTimeToISO(inst.Time);
        // need to convert Imports to EST for the chart.
        // they come in as UTC. This is hardcoded to 5 because the imports are ALWAYS EST. (never edt)
        isoDate.setHours(isoDate.getHours() - 5);
        actual.push({x: isoDate.getTime(), y: parseFloat(inst.Value)})
    });

    parseFirstTierData(ftData, firstTime);
}

function DownloadAllThree(format: string): void{
    Download(`${process.env.REACT_APP_DATABROKER_URL}${SCHEDULED_INTERCHANGE_SUFFIX}`.replace('json', format), "ScheduledInterchange", format);
    Download(`${process.env.REACT_APP_DATABROKER_URL}${FIRST_TIER_SCHEDULED_SUFFIX}`.replace('json', format), "FirstTierScheduledInterchange", format);
    Download(`${process.env.REACT_APP_DATABROKER_URL}${ACTUAL_INTERCHANGE_SUFFIX}`.replace('json', format), "ActualInterchange", format);
}

export default function Interchange(props: ChartProps) {
    
    const [options, setOptions] = useState(initialOptions);
    const chartRef = useRef<HighchartsReactRefObject>(null);
    const [showModal, setShowModal] = useState(false);
    const [modalBody, setModalBody] = useState(<></>);
    const modalHeader: React.ReactNode = <span>Region-Wide Total Imports(-)/Exports(+)</span>;

NoDataToDisplay(Highcharts);
    
    useEffect(() => {
    const descriptionBody: React.ReactNode = <>
        <span>The imports (negative values)/exports (positive values) chart provides a real-time display of scheduled and actual energy flows into, out of, and throughout the MISO transmission system. Values exclude dynamic schedules. Values for specific Balancing Authorities (e.g. AECI, LGEE, MHEB etc.) represent net scheduled interchanges for MISO's first-tier interfaces.</span>
        <br /><br />
        <span>The graph is updated every 5 minutes and is an average for the current five minute interval.</span>
        <br /><br />
        <span>Data provided in the XML document has minute-by-minute values for a rolling 24 hours.</span>
        <br /><br />
        <span>
        <b>First-Tier Balancing Authorities:</b>
        <br />
        AECI: Associated Electric Cooperative Incorporated
        <br />
        LGEE: LG&E
        <br />
        MHEB: Manitoba
        <br />
        Ont: Ontario
        <br />
        PJM: PJM
        <br />
        SOCO: Southern Company
        <br />
        SPA: Southwestern Power Administration
        <br />
        SWPP: Southwest Power Pool
        <br />
        TVA: Tennessee Valley Authority
        </span>
    </>;
        let downloadBody: React.ReactNode = <div className="download-modal">
            <Button className="download-btn" onClick={() => {DownloadAllThree("json")}}>Download JSON</Button>
            <Button className="download-btn" onClick={() => {DownloadAllThree("csv")}}>Download CSV</Button>
            <Button className="download-btn" onClick={() => {DownloadAllThree("xml")}}>Download XML</Button>
        </div>;
        switch (props.buttonClicked) {
            case DESCRIPTION_MESSAGE:
                setModalBody(descriptionBody);
                setShowModal(true);
                break;
            case DOWNLOAD_MESSAGE:
                setModalBody(downloadBody);
                setShowModal(true);
                break;
            case POPOUT_MESSAGE:
                const newWinddow = window.open('/charts/interchange', '_blank', 'width=800,height=600,noopener,noreferrer');
                if (newWinddow) newWinddow.opener = null;
                break;
            default:
                break;
        }
    }, [props.buttonClicked]);
    
    useEffect(() => {
        function fetchData(): void {
            if(chartRef.current === null){
                return;
            }
            const chart = chartRef.current.chart;
            chart.showLoading();

            Promise.all([
                fetch(process.env.REACT_APP_DATABROKER_URL + "" + SCHEDULED_INTERCHANGE_SUFFIX),
                fetch(process.env.REACT_APP_DATABROKER_URL + "" + ACTUAL_INTERCHANGE_SUFFIX),
                fetch(process.env.REACT_APP_DATABROKER_URL + "" + FIRST_TIER_SCHEDULED_SUFFIX)
            ])
            .then(responses => 
                Promise.all(responses.map(response => response.json()))
            )
            .then(data => {
                parseData(data as [NsiData, ImportInterval[], FirstTierData]);
                setOptions({
                    xAxis: [
                        // Main axis
                        {
                            title: {
                                text: `Hours EST`,
                                style: {
                                    fontWeight: 'bold',
                                    color: MisoColors.black,
                                },
                            },
                            labels: {
                                formatter: function(this: Highcharts.AxisLabelsFormatterContextObject) {
                                    var dt = new Date(this.value);
                                    return dt.getHours().toString();
                                },
                            },
                            type: 'datetime',
                            tickInterval: X_TICK_INTERVAL,
                        },
                        // axis to add date to left
                        {
                            title: {
                                text: `${prevDayFriendly}`,
                                align: "low",
                                style: {
                                    fontWeight: 'bold',
                                    color: MisoColors.black,
                                },
                                offset: -30,
                            },
                            lineWidth: 0,
                        },
                        // axis to add date to right
                        {
                            title: {
                                text: `${mktDayFriendly}`,
                                align: "high",
                                style: {
                                    fontWeight: 'bold',
                                    color: MisoColors.black,
                                },
                                offset: -30,
                            },
                            lineWidth: 0,
                        }
                    ],
                    series: [
                        {
                            name: "Net Scheduled Interchange",
                            type: "line",
                            data: netScheduled,
                            color: MisoColors.misoBlue,
                        },
                        {
                            name: "Net Actual Interchange",
                            type: "line",
                            data: actual,
                            color: MisoColors.red,
                        },
                        {
                            name: "AEC",
                            type: "line",
                            data: AEC,
                            visible: false,
                            showInLegend: false,
                        },
                        {
                            name: "AECI",
                            type: "line",
                            data: AECI,
                            visible: false,
                        },
                        {
                            name: "CSWS",
                            type: "line",
                            data: CSWS,
                            visible: false,
                            showInLegend: false,
                        },
                        {
                            name: "GLHB",
                            type: "line",
                            data: GLHB,
                            visible: false,
                            showInLegend: false,
                        },
                        {
                            name: "LGEE",
                            type: "line",
                            data: LGEE,
                            visible: false,
                        },
                        {
                            name: "MHEB",
                            type: "line",
                            data: MHEB,
                            visible: false,
                        },
                        {
                            name: "OKGE",
                            type: "line",
                            data: OKGE,
                            visible: false,
                            showInLegend: false,
                        },
                        {
                            name: "ONT",
                            type: "line",
                            data: ONT,
                            visible: false,
                        },
                        {
                            name: "PJM",
                            type: "line",
                            data: PJM,
                            visible: false,
                        },
                        {
                            name: "SOCO",
                            type: "line",
                            data: SOCO,
                            visible: false,
                        },
                        {
                            name: "SPA",
                            type: "line",
                            data: SPA,
                            visible: false,
                        },
                        {
                            name: "SWPP",
                            type: "line",
                            data: SWPP,
                            visible: false,
                        },
                        {
                            name: "TVA",
                            type: "line",
                            data: TVA,
                            visible: false,
                        },
                        {
                            name: "WAUE",
                            type: "line",
                            data: WAUE,
                            visible: false,
                            showInLegend: false,
                        },
                    ]
                })
            }).catch(() => {})
            chart.hideLoading();

            // Fixes rendering bug on iOS devices
            chart.redraw();
            chart.reflow();
        }

        fetchData();
        const FIFTEEN_MIN_MS = 1000 * 60 * 15;
        const interval = setInterval(() => fetchData(), FIFTEEN_MIN_MS)

        // return fires on unmount, prevent memory leak
        return() => clearInterval(interval);
    }, []);

    return (
        <div className='chart'>
            <VCenteredModal show={showModal} onHide={() => setShowModal(false)} headercontent={modalHeader} bodycontent={modalBody} />
            <div className="interval">{intervalString}</div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
            />
            <div className="legend-buttons-container">
            <FormCheckInput type="checkbox" onChange={(e) => {
                if(chartRef.current === null){
                    return;
                }
                const chart = chartRef.current.chart;
                chart.series.forEach(series => {
                    if(firstTierBas.includes(series.name)){
                        series.setVisible(e.target.checked);
                    }
                });
                
            } } className="margin-right-5" />
            <FormCheckLabel>Show all First-Tier Balancing Authorities</FormCheckLabel>
            </div>
        </div>
    )
}